<script lang="ts" setup>
import { useMobileViewStore, MobileView } from '@register'

const store = useMobileViewStore()
const { isGroupsMobileView, isFieldsMobileView, isPDFMobileView } =
  storeToRefs(store)
const leaseId = getCurrentLeaseId()
const { createDivisionURL } = useDivisions()
const { documents } = useCurrentLease()

const route = useRoute()
const canShowMobileButtons = computed(() => {
  return (
    route.path === createDivisionURL(`/lease/${leaseId}/lease`) ||
    route.path.startsWith(createDivisionURL(`/lease/${leaseId}/manager`))
  )
})
const hasPDF = computed(
  () =>
    documents.value.length > 0 &&
    route.path === createDivisionURL(`/lease/${leaseId}/lease`),
)
</script>

<template>
  <div
    v-if="canShowMobileButtons"
    class="bottom-safe-bottom pb-safe-bottom fixed left-0 right-0 z-[900] bg-gray-900 md:hidden print:hidden"
  >
    <div class="mx-auto flex justify-center p-3">
      <span
        role="button"
        tabindex="0"
        class="rounded-l-lg px-4 py-2 text-xs"
        :class="[isGroupsMobileView ? 'bg-gray-700' : 'bg-gray-800']"
        @click="store.setViewOnMobile(MobileView.GROUPS)"
        @keyup.enter="store.setViewOnMobile(MobileView.GROUPS)"
      >
        Groups
      </span>

      <span
        role="button"
        tabindex="0"
        class="px-4 py-2 text-xs"
        :class="[
          isFieldsMobileView ? 'bg-gray-700' : 'bg-gray-800',
          !hasPDF && 'rounded-r-lg',
        ]"
        @click="store.setViewOnMobile(MobileView.FIELDS)"
        @keyup.enter="store.setViewOnMobile(MobileView.FIELDS)"
      >
        Fields
      </span>

      <span
        v-if="hasPDF"
        role="button"
        tabindex="0"
        class="rounded-r-lg px-4 py-2 text-xs"
        :class="[isPDFMobileView ? 'bg-gray-700' : 'bg-gray-800']"
        @click="store.setViewOnMobile(MobileView.PDF)"
        @keyup.enter="store.setViewOnMobile(MobileView.PDF)"
      >
        PDF
      </span>
    </div>
  </div>
</template>
